import { useStaticQuery } from 'gatsby';
import * as React from 'react';
import { LayoutProvider } from '../components/layout';
import {
  CompareProductsCarousel,
  CategoryMainBanner,
  ProductSorting,
  CategoryActionBanner,
  CategoryCTABanner,
  CategorySpecsBenefits,
  CategoriesCarousel,
} from '../components/sections';
import { useViewPortSize } from '../hooks';
import { IHomePage, query } from '../pages';

interface ProductCategory {
  pageContext: {
    products: any;
    capacityFilter: any;
    mainBanner: any;
    actionBanner: any;
    ctaBanner: any;
    specsAndBenefits: any;
  };
  location: { search: string | undefined };
}

const ProductCategory = (props: ProductCategory) => {
  const data = props.pageContext.products;
  const capacityFilter = props.pageContext.capacityFilter;
  const mainBanner = props.pageContext.mainBanner;
  const actionBanner = props.pageContext.actionBanner;
  const ctaBanner = props.pageContext.ctaBanner;
  const specsAndBenefits = props.pageContext.specsAndBenefits;
  const dataCarousel = useStaticQuery<IHomePage>(query);

  const [isMobile, isTablet] = useViewPortSize();
  let addTitle = props.location.search
    ? props.location.search.split('=')[1].split('+').join(' ')
    : '';

  //Deleting current category from array
  const value = data.map((item: any) => item.node.stack_on_category.category);
  const cleanCarrousel = dataCarousel.strapiHomePage.categoryCarousel.reduce(
    (acc, curr) => { if (curr.category.category != value[1]) acc.push(curr); return acc}, []
  );


  return (
    <LayoutProvider seo={false} locationSearch={props.location.search} titleExt={addTitle}>
      <CategoryMainBanner mainBanner={mainBanner} />
      <ProductSorting productData={data} capacityFilter={capacityFilter} />
      <CategorySpecsBenefits specsAndBenefits={specsAndBenefits} />
      <CategoryCTABanner ctaBanner={ctaBanner} />
      <CompareProductsCarousel productData={data} capacityFilter={capacityFilter} />
      <CategoryActionBanner actionBanner={actionBanner} />
      <CategoriesCarousel categories={cleanCarrousel} />
    </LayoutProvider>
  );
};

export default ProductCategory;
